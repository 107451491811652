import axios from 'axios';
import { UNEXPECTED_RESPONSE_STATUS } from '../store/constant';
import Cookies from 'js-cookie';
import { API_URL } from './config';

const apiService = axios.create({
  baseURL: API_URL,
});

const resultResponse = (response) => {
  if (response.status === 200) {
    return { success: true, data: response.data };
  } else {
    return {
      success: false,
      error: `${UNEXPECTED_RESPONSE_STATUS}: ${response.status}`
    };
  }
};
const resultFieldResponse = (error) => {
  if (error.response) {
    return { success: false, errors: error.response.data.data };
  } else {
    return { success: false, error: 'Something went wrong' };
  }
};

const isUserExist = () => {
  return Cookies.get('user') ? true : false;
};

// get user name & email
const getCurrentUser = () => {
  return JSON.parse(Cookies.get('user') || '{}');
};

// get user id
const getUserId = () => {
  let user = JSON.parse(Cookies.get('user') || '{}');
  return parseInt(user.id);
};

const getToken = () => {
  return JSON.parse(Cookies.get('_session') || '{}');
};

const storeDataToStorage = (response) => {
  Cookies.set('user', JSON.stringify(response.use_detail), { expires: 7 });
  Cookies.set('is_verified', JSON.stringify(response.is_verified), { expires: 7 });
  Cookies.set('_session', JSON.stringify(response.token), { expires: 7 });
  localStorage.setItem("authtoken", response.token);
};


const logout = async () => {
  Cookies.remove('user');
  Cookies.remove('_rand');
  Cookies.remove('is_verified');
  Cookies.remove('_session');
  localStorage.clear();
};

const registration = async (data) => {
  try {
    const response = await apiService.post(`/register`, data);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
};

const login = async (data) => {
  try {
    const response = await apiService.post(`login`, data);
    return resultResponse(response);
  } catch (error) {
    return ("Please login again or check your credentials");
  }
};

const resendMailToken = async (userId) => {
  try {
    const response = await apiService.post(`/resend-register-mail?userId=${userId}`);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
}
const emailVerification = async (data) => {
  try {
    const response = await apiService.post(`/verify-email`, data);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
};


const sendForgotPasswordMail = async (data) => {
  try {
    const response = await apiService.post(`/forgot-password-mail`, data);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
}
const sendForgotPassword = async (data) => {
  try {
    const response = await apiService.post(`/forgot-password`, data);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
}
const changePassword = async (data) => {
  try {
    const response = await apiService.post(`/change-password`, data);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
}
const updateProfile = async (data) => {
  try {
    const response = await apiService.post(`/update-profile`, data);
    return resultResponse(response);
  } catch (error) {
    return resultFieldResponse(error);
  }
}
export const AuthApiService = {
  getCurrentUser,
  getUserId,
  logout,
  getToken,
  registration,
  resendMailToken, emailVerification, sendForgotPasswordMail, sendForgotPassword,
  login, changePassword, updateProfile,
  storeDataToStorage,
  isUserExist
};
