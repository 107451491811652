import {
  IconBrandChrome,
  IconHelp,
  IconDashboard,
  IconKey,
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconCoinRupee
} from '@tabler/icons';
import HotelIcon from '@mui/icons-material/Hotel';
// import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconDashboard,
  HotelIcon,
  IconKey,
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconCoinRupee
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'hotel-list',
      title: 'My Properties',
      type: 'item',
      url: '/hotel',
      icon: icons.HotelIcon,
      breadcrumbs: false
    },
    // {
    //   id: 'properties',
    //   title: 'properties',
    //   type: 'item',
    //   url: '/properties',
    //   icon: icons.IconDashboard,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'Bookings',
    //   title: 'Bookings',
    //   type: 'item',
    //   url: '/bookings',
    //   icon: LocalLibraryIcon,
    //   breadcrumbs: false
    // },

    // {
    //   id: 'sample-page',
    //   title: 'Sample Page',
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: icons.IconDashboard,
    //   breadcrumbs: false 
    // },
    // {
    //   id: 'coupons',
    //   title: 'Coupons',
    //   type: 'item',
    //   url: '/coupons',
    //   icon: icons.IconCoinRupee,
    //   breadcrumbs: false
    // },
  ]
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

// const pages = {
//   id: 'pages',
//   title: 'Pages',
//   caption: 'Pages Caption',
//   type: 'group',
//   children: [
//     {
//       id: 'util-shadow',
//       title: 'Shadow',
//       type: 'item',
//       url: '/utils/util-shadow',
//       icon: icons.IconShadow,
//       breadcrumbs: false
//     },
//     // {
//     //   id: 'authentication',
//     //   title: 'Authenticaddtion',
//     //   type: 'collapse',
//     //   icon: icons.IconKey,

//     //   children: [
//     //     {
//     //       id: 'login',
//     //       title: 'Login',
//     //       type: 'item',
//     //       url: '/login',
//     //       // target: true
//     //     },
//     //     {
//     //       id: 'register',
//     //       title: 'Register',
//     //       type: 'item',
//     //       url: '/register',
//     //       // target: true
//     //     }
//     //   ]
//     // }
//   ]
// };
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard] //, pages
};

export default menuItems;
