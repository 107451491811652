import { COMPLETE_STEP, CONTENT_STEP, HOTEL_INFO, PROPERTY_TYPE, SET_PRELOADER } from '../hotelAction';
const initialState = {
  loading: false,
  currentStep: 0,
  stepsCompleted: 0,
  property_type: null,
  hotelInfo: null,
  steps: [
    {
      name: "HOTEL DETAILS",
      slug: "/hotel/create"
    },
    {
      name: "Hotel Content",
      slug: "/hotel/hotel-content"
    },
    {
      name: "Hotel Images",
      slug: "/hotel/hotel-images"
    },
    {
      name: "Hotel Rooms",
      slug: "/hotel/hotel-rooms"
    },
    {
      name: "Hotel Nearby Places",
      slug: "/hotel/hotel-nearby-places"
    },
    {
      name: "Hotel Policy",
      slug: "/hotel/hotel-policy"
    },
    {
      name: "Hotel Finance",
      slug: "/hotel/hotel-finance"
    },
    {
      name: "Agreement",
      slug: "/hotel/agreement"
    }
  ]
};
const rootReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case COMPLETE_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        stepsCompleted: state.stepsCompleted + 1,
      };
    case CONTENT_STEP:
      return {
        ...state,
        currentStep: payload.step ?? 0
      };
    case HOTEL_INFO:
      return {
        ...state,
        hotelInfo: payload.hotel ?? null,
        stepsCompleted: payload.hotel?.step_completed ?? 0,
        currentStep: payload.hotel?.steps ?? 0
      };
    case SET_PRELOADER:
      return {
        ...state,
        loading: state.loading ? false : true
      };
    case PROPERTY_TYPE:
      return {
        ...state,
        property_type: payload?.data?.property_type ?? null,
      };
    default:
      return state;
  }
};
export default rootReducer;