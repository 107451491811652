import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import HotelLayout from 'layout/HomeLayout';
const RateAndInventory = Loadable(lazy(() => import('views/RateAndInventory')));
const HourlyRateAndInventory = Loadable(lazy(() => import('views/HourlyRateAndInventory')));
const Bookings = Loadable(lazy(() => import('views/booking')));
const Coupons = Loadable(lazy(() => import('views/coupons')));

// const Dashboard = Loadable(lazy(() => import('views/hotel/dashboard')));
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const Page404 = Loadable(lazy(() => import('views/errors/page404')));
const Promotions = Loadable(lazy(() => import('views/promotions')));
const PromotionType = Loadable(lazy(() => import('views/promotions/promotionType')));
const Payments = Loadable(lazy(() => import('views/payments')));

// property section 
const BasicBnfo = Loadable(lazy(() => import('views/property/basic-info')));
const RoomsInfo = Loadable(lazy(() => import('views/property/rooms-info')));
const Photos = Loadable(lazy(() => import('views/property/photos')));
const Policies = Loadable(lazy(() => import('views/property/policies')));
const NearByPlace = Loadable(lazy(() => import('views/property/near-by-place')));
const Settings = Loadable(lazy(() => import('views/property/settings')));
const FinancialDetails = Loadable(lazy(() => import('views/property/financial-details')));


const DayBasedPromotion = Loadable(lazy(() => import('views/promotions/DayBasedPromotion')));
const EarlyBirdPromotion = Loadable(lazy(() => import('views/promotions/EarlyBirdPromotion')));
const LastMinutePromotion = Loadable(lazy(() => import('views/promotions/LastMinutePromotion')));
const LongStayPromotion = Loadable(lazy(() => import('views/promotions/LongStayPromotion')));
const FreeNightPromotion = Loadable(lazy(() => import('views/promotions/FreeNightPromotion')));
const SponsoredPromotion = Loadable(lazy(() => import('views/promotions/SponsoredPromotion')));
const LastMinuteRate = Loadable(lazy(() => import('views/promotions/LastMinuteRate')));
const InsightTrends = Loadable(lazy(() => import('views/insight-trends')));



const HotelRoutes = {
  path: '/hotel',
  element: <HotelLayout />,
  children: [
    {
      path: 'dashboard/:hotelId',
      element: <Dashboard />
    },
    {
      path: '*',
      element: <Page404 />
    },
    {
      path: 'inventory/:hotelId',
      element: <RateAndInventory />
    },
    {
      path: 'hourly-price-list/:hotelId',
      element: <HourlyRateAndInventory />
    },
    {
      path: 'bookings/:hotelId',
      element: <Bookings />
    },
    {
      path: 'coupons/:hotelId',
      element: <Coupons />
    },
    {
      path: 'promotions/:hotelId',
      element: <Promotions />
    },
    {
      path: 'promotion/create/:hotelId',
      element: <PromotionType />
    },
    {
      path: 'promotion/day-based/create/:hotelId',
      element: <DayBasedPromotion />
    },
    {
      path: 'promotion/day-based/edit/:id/:hotelId',
      element: <DayBasedPromotion />
    },
    {
      path: 'promotion/early-bird/create/:hotelId',
      element: <EarlyBirdPromotion />
    },
    {
      path: 'promotion/early-bird/edit/:id/:hotelId',
      element: <EarlyBirdPromotion />
    },
    {
      path: 'promotion/last-minute/create/:hotelId',
      element: <LastMinutePromotion />
    },
    {
      path: 'promotion/last-minute/edit/:id/:hotelId',
      element: <LastMinutePromotion />
    },
    {
      path: 'promotion/long-stay/create/:hotelId',
      element: <LongStayPromotion />
    },
    {
      path: 'promotion/long-stay/edit/:id/:hotelId',
      element: <LongStayPromotion />
    },
    {
      path: 'promotion/free-night/create/:hotelId',
      element: <FreeNightPromotion />
    },
    {
      path: 'promotion/free-night/edit/:id/:hotelId',
      element: <FreeNightPromotion />
    },
    {
      path: 'promotion/sponsored/create/:hotelId',
      element: <SponsoredPromotion />
    },
    {
      path: 'promotion/sponsored/edit/:id/:hotelId',
      element: <SponsoredPromotion />
    },
    {
      path: 'promotion/last-minute-rate/create/:hotelId',
      element: <LastMinuteRate />
    },
    {
      path: 'promotion/last-minute-rate/edit/:id/:hotelId',
      element: <LastMinuteRate />
    },
    {
      path: 'payments/:hotelId',
      element: <Payments />
    },
    {
      path: 'basic-info/:hotelId',
      element: <BasicBnfo />
    },
    {
      path: 'rooms/:hotelId',
      element: <RoomsInfo />
    },
    {
      path: 'photos/:hotelId',
      element: <Photos />
    },
    {
      path: 'near-by-place/:hotelId',
      element: <NearByPlace />
    },
    {
      path: 'policies/:hotelId',
      element: <Policies />
    },
    {
      path: 'settings/:hotelId',
      element: <Settings />
    },
    {
      path: 'financial-details/:hotelId',
      element: <FinancialDetails />
    },
    {
      path: 'insight-trends/:hotelId',
      element: <InsightTrends />
    },
  ]
}

export default HotelRoutes