import { useRoutes } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
const EmailVerification = Loadable(lazy(() => import('views/pages/authentication/authentication/EmailVerification')));

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import HotelRoutes from './HotelRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, AuthenticationRoutes, HotelRoutes,
    {
      path: '/verify-email/:email/:token',
      element: <EmailVerification />
    },
  ]);
}
